<template>
  <div class="carWash">
    <TopTabbar title="店铺详情" text="返回"/>
    <div class="center">
      <img :src="shopInfo.parking_img ? 'https://wyxc.047x.com'+shopInfo.parking_img : ''">
      <h1>{{ shopInfo.shop_name }}({{ shopInfo.name }})</h1>
      <div class="nav">
        <div class="status">
          <span>工位状态:</span>
          <span v-if="shopInfo.status == 0">空闲</span>
          <span v-if="shopInfo.status == 1">使用中</span>
          <span v-if="shopInfo.status == 1">{{ useTimes }}</span>
        </div>
        <div class="about">
          <div class="text" @click="phone">
            <van-icon name="phone" size="18"/>
            电话
          </div>
          <div class="text" @click="phone">
            <van-icon name="warning-o" size="18"/>
            故障
          </div>
        </div>
      </div>
      <div class="balance">
        剩余洗车次数: <span> {{card}} </span> 张
      </div>
      <div class="switch">
        <div class="switch_1" v-if="swh">
          <van-button type="primary" color="#ed5250" @click="switchDoor(1)">开门</van-button>
          <van-button type="primary" color="#ed5250" @click="switchDoor(2)">关门</van-button>
        </div>
        <van-button type="primary" class="switch_2" color="#ed5250" v-if="swh" @click="endCarWash" :disabled="endDisabled">{{ endBtn }}</van-button>
        <van-button type="primary" class="switch_2" color="#ed5250" v-if="!swh" @click="startCarWash" :disabled="startDisabled || shopInfo.status != 0">{{ startBtn }}</van-button>
      </div>
      <div class="footer">
        <p>使用说明:</p>
        <p>一次可使用30分钟，超出30分钟须重新扫码。</p>
      </div>
    </div>
    <van-popup
      v-model:show="showBottom"
      round
      position="bottom"
      :style="{ height: '70%' }"
    >
      <div class="popup_top">
        <div class="left">
          <span>
            已使用:
            <span>{{ cardList.user_count }}</span>
          </span>
          <span>
            未使用:
            <span>{{ cardList.no_count }}</span>
          </span>
        </div>
        <van-icon name="cross" size="30" @click="showBottom = false"/>
      </div>
      <div class="popup_center">
        <div class="item" v-for="i in cardList.arr" :key="i.id" v-show="i.status == 0">
          <div class="left">
            <h1>洗车卡</h1>
            <p>可用时长:<span>{{ i.times }}</span>分钟</p>
            <p>截止日期:<span>{{ getTime2(i.end_time) }}</span></p>
          </div>
          <van-button plain type="primary" size="small" @click="use(i.id)">立即使用</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
  import { showToast,showDialog } from 'vant'
  import { ref,onMounted, reactive } from 'vue'
  import { reqGetParkingInfo,reqGetUserOrder,reqGetShopUserCard,reqStartCatWash,reqEndCatWash,reqSwitchDoor } from '../../API/home'
  import { reqGetMyCard,reqUserCard } from '../../API/user'
  import { useRouter } from 'vue-router'

  const $router = useRouter()
  const swh = ref(false)
  const shopInfo = reactive({})     //店铺信息
  const parking_id = ref()         //工位id
  localStorage.setItem('parking_id',parking_id.value)
  const card = ref('')              //剩余次卡
  const startBtn = ref('洗车')
  const startDisabled = ref(false)
  const endBtn = ref('结束洗车')
  const endDisabled = ref(false)
  const useTimes = ref()
  const use_time = ref()
  const showBottom = ref(false)
  const cardList = reactive({
    arr:[],
    no_count:'',
    user_count:'',
  })
  let timer = ref()

  onMounted(async () => {
    const str = window.location.href
    const index = str.indexOf('parking_id=')
    if(index != -1){
      parking_id.value = str.slice(index+11,str.length)
      localStorage.setItem('parking_id',parking_id.value)
    }
    if(!localStorage.getItem('TOKEN')) $router.push('/login')
    await getParkingInfo()
    getUserOrder()  
  })

  //获取店铺信息
  const getParkingInfo = async () => {
    clearInterval(timer.value)
    const result = await reqGetParkingInfo({
      parking_id:parking_id.value
    })
    console.log("店铺信息",result);
    if(result.code == 1){
      Object.assign(shopInfo,result.data.info)
      getShopUserCard()
      getMyCard()
      
      timer.value = setInterval(() => {
        if(result.data.info.use_time != ''){
          console.log(result.data.info.use_time);
          useTimes.value = getTime(result.data.info.use_time)
        }else{
          console.log('qwe');
          clearInterval(timer.value)
        }
      },1000)
    }
  }

  //获取洗车状态
  const getUserOrder = () => {
    const timer2 = setInterval(async () => {
      const result = await reqGetUserOrder({
        parking_id:parking_id.value,
        shop_id:shopInfo.shop_id,
        id:localStorage.getItem('thisCardId')
      })
      if(result.code == 1){
        swh.value = true
      }else{
        clearInterval(timer2)
        getParkingInfo()
        swh.value = false
      }
    },1000)
    
    //console.log("洗车状态",result);
  }

  //获取店铺账户信息
  const getShopUserCard = async () => {
    const result = await reqGetShopUserCard({
      shop_id:shopInfo.shop_id
    })
    if(result.code == 1) card.value = result.data.surplus_many
  }

  //获取次卡
  const getMyCard = async () => {
    const result = await reqGetMyCard({
      shop_id:shopInfo.shop_id
    })
    if(result.code == 1){
      cardList.arr = result.data
      cardList.no_count = result.count.no_count
      cardList.user_count = result.count.user_count
    }
    console.log("次卡",result);
  }

  //使用洗车卡
  const use = async (id) => {
    showBottom.value = false
    localStorage.setItem('thisCardId',id)
    getMyCard()
    getShopUserCard()
    const result = await reqStartCatWash({
      parking_id:parking_id.value,
      shop:shopInfo.shop_id,
      many_id:id
    })
    if(result.code == 1){
      startBtn.value = '开门洗车'
      startDisabled.value = false
      getParkingInfo()
      getUserOrder()
    }
    showToast(result.msg)
    //console.log("开门洗车",result2);
    //console.log(id);
  }

  //开门洗车
  const startCarWash = async () => {
    showBottom.value = true
  }

  //结束洗车
  const endCarWash = async () => {
    endBtn.value = '结束订单中。。。'
    endDisabled.value = true
    const result = await reqEndCatWash({
      parking_id:parking_id.value,
      shop:shopInfo.shop_id,
      many_id:localStorage.getItem('thisCardId')
    })
    //console.log("结束洗车",result);
    if(result.code == 1){
      endBtn.value = '结束订单'
      endDisabled.value = false
      getParkingInfo()
    }
    showToast(result.msg)
  }

  //开关门
  const switchDoor = async (type) => {
    const result = await reqSwitchDoor({
      parking_id:parking_id.value,
      shop:shopInfo.shop_id,
      type
    })
    showToast(result.msg)
    //console.log(result);
  }

  //客服电话
  const phone = () => {
    showDialog({
      title: shopInfo.mobile,
      theme: 'round-button',
    }).then(() => {
      // on close
    });
  }

  //获取使用时间(秒)
  const getTime = (time) => {
    var times = parseInt((new Date()) / 1000);
    times = times - time;
    var days = Math.floor(times / 3600 / 24);
    var hours = Math.floor((times - days * 24 * 3600) / 3600);
    var mins = Math.floor((times - days * 24 * 3600 - hours * 3600) / 60);
    var secs = Math.floor((times - days * 24 * 3600 - hours * 3600 - mins * 60));
    if(hours < 10){
      hours = '0'+ hours;
    }
    if(mins < 10){
      mins = '0'+ mins;
    }
    if(secs < 10){	
      secs = '0'+ secs;
    }
    return hours + ':' + mins + ':' + secs;
  }

  //获取使用时间(完整)
  const getTime2 = (time) => {
    var times = new Date(time * 1000)
    return times.toLocaleString();
  }
</script>

<style lang="scss" scoped>
  .carWash{
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    overflow: hidden;
    .center{
      width: 100%;
      height: calc(100% - 46px);
      img{
        width: 100%;
        height: 30vh;
      }
      h1{
        font-weight: bold;
        margin-top: 5px;
        padding: 0 5px;
      }
      .nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        margin-top: 10px;
        .status{
          span{
            color: white;
            padding: 3px;
            font-size: 14px;
            border-radius: 5px;
            margin-right: 5px;
            &:nth-of-type(1){
              background-color: #007aff;
            }
            &:nth-of-type(2){
              background-color: #18bc37;
            }
            &:nth-of-type(3){
              background-color: rgb(109, 165, 250);
            }
            &:nth-of-type(4){
              color: black;
              font-size: 16px;
            }
          }
        }
        .about{
          display: flex;
          font-size: 13px;
          .text{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &:nth-of-type(2){
              margin-left: 10px;
            }
          }
          .van-badge__wrapper{
            background-color: #ebeef5;
            padding: 5px;
            border-radius: 50%;
            margin-block: 3px;
          }
        }
      }
      .list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 5px;
        font-size: 16px;
        margin-top: 5px;
        span{
          background-color: #d1ecf5;
          color: #77bce2;
          padding: 3px;
          margin-right: 5px;
          border-radius: 5px;
        }
      }
      .balance{
        width: 100%;
        background-color: #f8f8f8;
        height: 57px;
        line-height: 57px;
        margin-top: 10px;
        font-weight: bold;
        padding-left: 15px;
        span{
          color: red;
          margin-left: 15px;
        }
      }
      .switch{
        width: 100%;
        padding: 10px 0;
        .switch_1{
          display: flex;
          justify-content: space-between;
          padding: 0 30px;
          margin-bottom: 10px;
          .van-button{
            flex: 0 0 46%;
          }
        }
        .switch_2{
          width: 80%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .footer{
        width: 100%;
        padding: 15px 15px;
        background-color: #f8f8f8;
        font-size: 14px;
        p{
          &:nth-of-type(2){
            text-indent: 2em;
            margin-top: 5px;
          }
        }
      }
    }
    .popup_top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px;
      .left{
        span{
          &:nth-of-type(2){
            margin-left: 15px;
          }
          span{
            color: red;
            font-weight: bold;
          }
        }
      }
    }
    .popup_center{
      width: 100%;
      height: calc(100% - 50px);
      font-size: 16px;
      overflow: auto;
      background-color: #f7f8fa;
      .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 96%;
        padding: 15px;
        background-color: white;
        margin: 10px auto 0px auto;
        border-radius: 15px;
        h1{
          font-weight: bold;
        }
        p{
          font-size: 15px;
          &:nth-of-type(1){
            margin: 10px 0;
          }
          span{
            margin-left: 7px;
            color: red;
            font-weight: bold;
          }
        }
      }
    }
  }
  ::v-deep(.van-button__text){
    font-size: 20px !important;
  }
</style>